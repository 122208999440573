<template>
  <div class="overflow-hidden">
    <div>
      <v-system-bar lights-out window class="justify-space-between" v-if="$route.name == 'Municipi'">
        Municipi:
        <template v-if="municipi">
          {{municipi}}
        </template>
        <v-btn-toggle group dense>
          <v-btn icon :disabled="year == 2010 ? true:false" v-on:click="$emit('getyear',-1); year -= 1">
            <v-icon>mdi-skip-previous</v-icon>
          </v-btn>
          <v-btn icon v-if="year">
            {{year}}
          </v-btn>
          <v-btn icon :disabled="year == new Date().getFullYear() ? true:false" v-on:click="$emit('getyear',1); year += 1;">
            <v-icon>mdi-skip-next</v-icon>
          </v-btn>

        </v-btn-toggle>
      </v-system-bar>

      <v-system-bar lights-out window class="justify-center"  v-if="$route.name == 'Home'">
        <v-btn-toggle group multiple>
          <v-btn>
            <v-icon>
              mdi-vector-point
            </v-icon>
          </v-btn>
          <v-btn >
            <v-icon>
              mdi-vector-line
            </v-icon>
          </v-btn>
          <v-btn >
            <v-icon>
              mdi-vector-square
            </v-icon>
          </v-btn>
          <v-btn >
            <v-icon class="filter-green">
              $vuetify.icons.custom
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-system-bar>
    </div>

    <v-bottom-navigation
      color="terciary"
      fixed
    >
    <v-btn to="/" icon :color="$route.name == 'Home' ? 'error' : null">
        <span>Rurals</span>
        <v-icon>mdi-home-analytics</v-icon>
      </v-btn>
      <v-btn to="/urba" icon :color="$route.name == 'Home' ? 'error' : null">
        <span>Urbans</span>
        <v-icon>mdi-home-analytics</v-icon>
      </v-btn>

        <v-btn to="/mapa" icon :color="$route.name == 'Home' ? 'error' : null">
          <span>Mapa</span>
          <v-icon>mdi-map-search-outline </v-icon>
        </v-btn>
      <v-btn href="/accounts/logout?next=/accounts/login-ajuntament">
        <span>Sortir</span>
        <v-icon>mdi-exit-run</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
import mdiLineUnder from '../assets/lineunder.svg'
  export default {
    props: {
      municipi: {
        type: String,
        default: null,
      }
    },
    data () {
      return {
        lineunder: mdiLineUnder,
        year: new Date().getFullYear(),
      }
    },
    methods: {
        getUser: async function fetchUrl() {
            let res = await fetch('/getuser');
            if (res.redirected) {
              window.location.href = res.url;
            }
            if (res.ok != true) {
              console.log("Error en getUser")
              window.location.href = "/accounts/login/";
            }
            let json = await res.json();
            this.username = json.userdata.fullname
            this.useremail = json.userdata.email
            this.userimg = json.userdata.img
        },
    },
    mounted() {
      this.getUser();
    },
  }
</script>

<style>
.filter-green{
    filter: opacity(60%)
}
</style>
