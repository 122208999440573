import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ca from 'vuetify/es5/locale/ca';
import UnderLine from '@/assets/Icons/UnderLine.vue'
import Mosquito from '@/assets/Icons/Mosquito.vue'
import Vial from '@/assets/Icons/Vial.vue'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { ca },
      current: 'ca',
    },
    icons: {
      iconfont: 'mdi', // default - only for display purposes
      values: {
        custom: { // name of our custom icon
          component: UnderLine, // our custom component
        },
        mosquito: { // name of our custom icon
          component: Mosquito, // our custom component
        },
        vial: { // name of our custom icon
          component: Vial, // our custom component
        },
      },
    },
    theme: {
      themes: {
        light: {
          primary: '#6cad4f',
          secondary: '#519cd2',
          terciary: '#4771A6',
          accent: '#519cd2',
          notselected: '#7598c4',
          error: '#7D9EAC',
          dash: '#7da8ac',
          header: '#247D72',
          custom:'#65a69e'
        },
      },
    },
});
