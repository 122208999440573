<template>
    <v-app-bar
      app
      clipped-right
      dark
      elevation="1"
      style="background: linear-gradient(45deg, #eeeeee 0%, #00695c 100%);"
      >
      <!-- <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer> -->

      <a to="/" class="d-flex justify-center align-center">
        <img to="/" src="../assets/logoConsellComarcal.png" height="58px"/>
      </a>
      <v-spacer></v-spacer>
      <a to="/" class="d-flex justify-center align-center">
        <img to="/" src="../assets/40anys2.png" height="58px"/>
      </a>
      <v-spacer></v-spacer>

      <!-- Routes -->
    <div class="d-print-none d-flex justify-center align-end">
       <v-btn to="/" class="ma-2" text rounded :colorx="$route.name == 'Home' ? 'error' : null">
           <v-icon left>mdi-home</v-icon>Tractaments rurals
        </v-btn>
        <v-btn to="/urba" class="ma-2" text rounded :colorx="$route.name == 'Home' ? 'exor' : null">
           <v-icon left>mdi-home</v-icon>Tractaments urbans
        </v-btn>
        <v-btn to="/mapa" class="ma-2" text rounded :colorx="$route.name == 'Municipi' ? 'error' : null">
           <v-icon left>mdi-map</v-icon>mapa
        </v-btn>
        <v-btn href="/accounts/logout?next=/accounts/login-ajuntament" class="ma-2" text rounded color="error">
           <v-icon left>mdi-exit-to-app</v-icon>Sortir
        </v-btn>
    </div>
    </v-app-bar>
</template>

<script>
  export default {
    data () {
      return {
      }
    },
    mounted() {
      this.getUser();
    },
    methods: {
      getData: async function fetchUrl() {
      },
      getUser: async function fetchUrl() {
        let res = await fetch('/getuser');
        if (res.redirected) {
          // window.location.href = res.url;
          window.location.href = "/accounts/login-ajuntament/";
        }
        if (res.ok != true) {
          console.log("Error en getUser")
          window.location.href = "/accounts/login-ajuntament/";
        }
        let json = await res.json();
        this.username = json.userdata.fullname
        this.useremail = json.userdata.email
        this.userimg = json.userdata.img
      },
    }
  }
</script>

<style >

.background: {
  background: linear-gradient(45deg, #eeeeee 0%, #00695c 100%);
  padding-top: 30px;

}
.title: {
  color: #fff;
  font-size: 16px;
  overflow-y: hidden;
}
.mosquit {
  position: relative;
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  left: 0;
}
.activetab{
  background-color: #222;
  color:#222;
}

</style>
