var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"98.5%"}},[(!_vm.fab)?_c('v-tooltip',{attrs:{"left":"","nudge-right":"5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"municipis",attrs:{"fab":"","dark":"","small":"","color":_vm.municipi ? 'terciary' : 'error'},on:{"click":_vm.onMunicipiClick}},on),[_c('v-icon',[_vm._v(" mdi-texture-box")])],1)]}}],null,false,3583643149)},[_c('span',[_vm._v("Termes municipals")])]):_vm._e(),_c('v-speed-dial',{staticClass:"v-btn",attrs:{"absolute":"","bottom":"","right":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{class:"elevation-0",attrs:{"color":"terciary","dark":"","large":"","fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-avatar',{attrs:{"size":"55"}},[_c('img',{attrs:{"src":_vm.mapa[_vm.num],"alt":"John"}})])],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-tooltip',{attrs:{"left":"","nudge-right":"5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"v-btn",attrs:{"fab":"","dark":"","large":"","color":"notselected"},on:{"click":function($event){_vm.selection((_vm.num+1)%4)}}},on),[_c('v-avatar',{attrs:{"size":"58"}},[_c('img',{attrs:{"src":_vm.mapa[(_vm.num+1)%4],"alt":"John"}})])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.mapa_name[(_vm.num+1)%4]))])]),_c('v-tooltip',{attrs:{"left":"","nudge-right":"5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"v-btn",attrs:{"fab":"","dark":"","large":"","color":"notselected"},on:{"click":function($event){_vm.selection((_vm.num+2)%4)}}},on),[_c('v-avatar',{attrs:{"size":"58"}},[_c('img',{attrs:{"src":_vm.mapa[(_vm.num+2)%4],"alt":"John"}})])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.mapa_name[(_vm.num+2)%4]))])]),_c('v-tooltip',{attrs:{"left":"","nudge-right":"5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"v-btn",attrs:{"fab":"","dark":"","large":"","color":"notselected"},on:{"click":function($event){_vm.selection((_vm.num+3)%4)}}},on),[_c('v-avatar',{attrs:{"size":"58"}},[_c('img',{attrs:{"src":_vm.mapa[(_vm.num+3)%4],"alt":"John"}})])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.mapa_name[(_vm.num+3)%4]))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }