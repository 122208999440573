<template>
  <div >
    <vl-map :load-tiles-while-animating="true" :load-tiles-while-interacting="true"
             data-projection="EPSG:3857" :style="{height: this.altura +'px'}"
             ref="map"
    >
      <vl-view :zoom.sync="zoom" :center="centre"></vl-view>
      <vl-layer-tile :visible="nmap[0]" >
        <vl-source-xyz :url="`https://api.mapbox.com/styles/v1/luisaguilar/ckfnqit6s16531anydlr0asuw/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibHVpc2FndWlsYXIiLCJhIjoiMUJWMnRBSSJ9.PRZA4GlLViSxzw_wpR6oEg`"></vl-source-xyz>
      </vl-layer-tile>
      <vl-layer-tile :visible="nmap[1]" >
        <vl-source-xyz :url="`https://api.mapbox.com/styles/v1/luisaguilar/ckfnr0j2503go19mq2u202wbp/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibHVpc2FndWlsYXIiLCJhIjoiMUJWMnRBSSJ9.PRZA4GlLViSxzw_wpR6oEg`"></vl-source-xyz>
      </vl-layer-tile>
      <vl-layer-tile :visible="nmap[2]" >
        <vl-source-xyz :url="`https://api.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiYWlzY2FkbW9iaWxlIiwiYSI6IjUyMGI3Yjc5NTZkYjhjYmY2NzFmZmEwZmU4YmI3NDg3In0.gXLJ8u6N936QOxfWKau-xg`"></vl-source-xyz>
      </vl-layer-tile>
      <vl-layer-tile :visible="nmap[3]">
        <vl-source-wms
          url="https://geoserveis.icgc.cat/icc_mapesbase/wms/service?"
          :ext-params="{ TILED: true, 'INFO_FORMAT': 'application/json'}"
          server-type="geoserver"
          layers="mtc5m"
          projection="EPSG:3857"
          version="1.1.0"
          :strategy-factory="loadingStrategyFactory"
          >
        </vl-source-wms>
      </vl-layer-tile>

      <!--Lineal-->

      <vl-layer-vector :visible="this.visible[0]">
        <vl-source-vector :url="urlCapa1" :strategy-factory="loadingStrategyFactory"></vl-source-vector>
        <vl-style-func :factory="styleFunction"/>

      </vl-layer-vector>

      <!--Superficial-->

      <vl-layer-vector :visible="this.visible[1]">
        <vl-source-vector :url="urlCapa2" :strategy-factory="loadingStrategyFactory"></vl-source-vector>
        <vl-style-func :factory="styleFunction"/>

      </vl-layer-vector>

      <!--Lienal soterrat-->

      <vl-layer-vector :visible="this.visible[2]">
        <vl-source-vector :url="urlCapa3" :strategy-factory="loadingStrategyFactory"></vl-source-vector>
        <vl-style-func :factory="styleFunction"/>

      </vl-layer-vector>

      <!--Puntual-->

      <vl-layer-vector :visible="this.visible[3]">
        <vl-source-vector :url="urlCapa4" :strategy-factory="loadingStrategyFactory"></vl-source-vector>
        <vl-style-box>

          <vl-style-circle :radius="7" v-if="zoom>14">
            <vl-style-fill color="#2962FF"></vl-style-fill>
            <vl-style-stroke :width="1" color="white"></vl-style-stroke>
          </vl-style-circle>

          <vl-style-circle :radius="zoom-8" v-else-if="zoom<=14 & zoom>8">
            <vl-style-fill color="#2962FF"></vl-style-fill>
            <vl-style-stroke :width="1" color="white"></vl-style-stroke>
          </vl-style-circle>

          <vl-style-circle :radius="2" v-if="zoom<=8">
            <vl-style-fill color="#2962FF"></vl-style-fill>
            <vl-style-stroke :width="1" color="white"></vl-style-stroke>
          </vl-style-circle>
        </vl-style-box>
      </vl-layer-vector>


      <!--Municipi-->

      <vl-layer-tile :visible="this.municipi">
        <vl-source-wms
          url="/geoserver/mosquits/wms"
          layers="mosquits:zona"
        ></vl-source-wms>
      </vl-layer-tile>
<!--
      <vl-layer-tile>
        <vl-source-wmts
      ></vl-source-wmts>
    </vl-layer-tile>-->

      <vl-interaction-select :features.sync="selected">
        <vl-style-box>
          <vl-style-stroke :width="3" color="#F99491"></vl-style-stroke>
          <vl-style-fill color="#F99491"></vl-style-fill>
          <vl-style-circle :radius="7" v-if="zoom>14">
            <vl-style-fill color="#F99491"></vl-style-fill>
            <vl-style-stroke :width="1" color="white"></vl-style-stroke>
          </vl-style-circle>
          <vl-style-circle :radius="zoom-8" v-else-if="zoom<=14 & zoom>8">
            <vl-style-fill color="#F99491"></vl-style-fill>
            <vl-style-stroke :width="1" color="white"></vl-style-stroke>
          </vl-style-circle>
          <vl-style-circle :radius="1" v-if="zoom<=8">
            <vl-style-fill color="#F99491"></vl-style-fill>
            <vl-style-stroke :width="1" color="white"></vl-style-stroke>
          </vl-style-circle>
        </vl-style-box>
      </vl-interaction-select>

      <!--  urba -->
      
      <!--capa reixes--> 
      <vl-layer-image  :z-index="10" :visible="urba[0]">
         <vl-source-image-wms
           :url=" '/geoserver/mosquits/wms?'"
           layers="mosquits:reixa"
           projection="EPSG:3857"
           version="1.3.0"
           :ratio="1"
          
           >
         </vl-source-image-wms>
       </vl-layer-image>
        <!--capa embornals-->
        <vl-layer-image  :z-index="10" :visible="urba[1]">
         <vl-source-image-wms
           :url=" '/geoserver/mosquits/wms?'"
           layers="mosquits:imbornal"
           projection="EPSG:3857"
           version="1.3.0"
           :ratio="1"
          
           >
         </vl-source-image-wms>
       </vl-layer-image>

    </vl-map>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline"
          style="background: linear-gradient(45deg, #eeeeee 0%, #00695c 100%);"
          primary-title
          v-if="focuss.id"
        >
          Focus: {{focuss.codi}}
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-btn
            color="#fff"
            text
            @click.stop="dialog=false"
          >
          <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text v-if="focuss.id">
          <v-list>


            <v-list-item
              class="mt-n2 ml-n4"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span class="grey--text subtitle-2 text--darken-1">Zona:</span>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action-text>
                <span class="grey--text subtitle-2 text--darken-3">{{focuss.zona_noms}}</span>
              </v-list-item-action-text>

            </v-list-item>

            <v-list-item
              class="mt-n2 ml-n4"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span class="grey--text subtitle-2 text--darken-1">Classe:</span>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action-text>
                <span class="grey--text subtitle-2 text--darken-3">{{focuss.tipusclasse_noms}}</span>
              </v-list-item-action-text>

            </v-list-item>

            <v-list-item
              class="mt-n2 ml-n4"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span class="grey--text subtitle-2 text--darken-1">Tipus de focus: </span>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action-text>
                <span class="grey--text subtitle-2 text--darken-3">{{focuss.classe_noms}}</span>
              </v-list-item-action-text>
            </v-list-item>

            <v-list-item
              class="mt-n2 ml-n4"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span class="grey--text subtitle-2 text--darken-1">Impacte: </span>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action-text>
                <span class="grey--text subtitle-2 text--darken-3">{{focuss.impacte_noms}}</span>
              </v-list-item-action-text>
            </v-list-item>

            <v-list-item
              class="mt-n2 ml-n4"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span class="grey--text subtitle-2 text--darken-1">Superficie total:</span>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action-text>
                <span class="grey--text subtitle-2 text--darken-3">{{focuss.superficie_total}}</span>
              </v-list-item-action-text>

            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import Vue from 'vue'
import VueLayers from 'vuelayers'
import { Map, TileLayer, OsmSource, XyzSource } from 'vuelayers'
import {Fill, Stroke, Style} from 'ol/style.js'; // Text,

import {loadingBBox} from 'vuelayers/lib/ol-ext'
//import * as ol_style from 'ol/style';

import 'vuelayers/lib/style.css' // needs css-loader
Vue.use(VueLayers)
Vue.use(Map)
Vue.use(TileLayer)
Vue.use(OsmSource)
Vue.use(XyzSource)

  export default {
    props: ['centre', 'altura','map','focus', 'municipi','urba'],
    data () {
      return {
        zoom: 12,
        dialog: false,
        focuss: [],
        center: this.centre,
        visible: [true,true,true,true],
        nmap: [true,false,false,false],
        selected: [],
        // urba:[false,false]
      }
    },
    watch: {
        focus: {
            handler: function() {
                this.visible = this.focus.map(bool => bool)
            }, deep: true
        },
        map: function(newval, oldval) {
            Vue.set(this.nmap, oldval, !this.nmap[oldval])
            Vue.set(this.nmap, newval, !this.nmap[newval])
        },
        selected: {
            handler: function() {
              this.loadFeatureInfo();
            }, deep: true,
        }
    },
    updated() {
    },
    methods: {
      styleFunction() {
        return function (feature, resolution) {
          var stroke_visible = 1;
          var fill_visible = 0.2;
          var line_dash = null;
          var stroke_width = 1;
          if (resolution <= 19) {
              stroke_width = 2;
          }
          var fill_color;
          var stroke_color;
          fill_color = 'rgba(41, 98, 255,' + fill_visible + ')';
          stroke_color = 'rgba(41, 98, 255,' + stroke_visible + ')';
          var tipus_focus = (feature.getProperties().tipus_focus).toUpperCase();
          //Focus puntual y ubicacio puntual dels conjunts de focus
          if (tipus_focus == "PUNTUAL") {
              return new Style({
                stroke: new Stroke({
                    width: stroke_width,
                    color: '#222',
                    lineDash: line_dash
                })
              });
          }
          //Focus linial y ubicacio linial dels conjunts de focus
          else if (tipus_focus == "LINIAL") {
              line_dash = null;
              return new Style({
                  stroke: new Stroke({
                      width: stroke_width,
                      color: stroke_color,
                      lineDash: line_dash
                  })
              });
          }
          else if (tipus_focus == "SOTERRADA") {

              line_dash = [2, 8];

              return new Style({
                  stroke: new Stroke({
                      width: stroke_width,
                      color: stroke_color,
                      lineDash: line_dash
                  })
              });

          }
          else if (tipus_focus == "SUPERFICIAL") {

              return new Style({
                  fill: new Fill({
                      color: fill_color
                  }),
                  stroke: new Stroke({
                      width: stroke_width,
                      color: stroke_color,
                      lineDash: line_dash
                  })
              });
          }
          else {
            return;
          }
        };
      },
      styleFunction2() {
        return function (feature, resolution) {
          var stroke_visible = 1;
          var fill_visible = 0.2;
          var line_dash = null;
          var stroke_width = 1;
          if (resolution <= 19) {
              stroke_width = 2;
          }
          var fill_color;
          var stroke_color;
            fill_color = 'rgba(255, 198, 41,' + fill_visible + ')';
            stroke_color = 'rgba(255, 198, 41,' + stroke_visible + ')';
          var tipus_focus = (feature.getProperties().tipus_focus).toUpperCase();
          //Focus puntual y ubicacio puntual dels conjunts de focus
          if (tipus_focus == "PUNTUAL") {

              return new Style({
                stroke: new Stroke({
                    width: stroke_width,
                    color: '#222',
                    lineDash: line_dash
                })
              });
          }
          //Focus linial y ubicacio linial dels conjunts de focus
          else if (tipus_focus == "LINIAL") {
              line_dash = null;
              return new Style({
                  stroke: new Stroke({
                      width: stroke_width,
                      color: stroke_color,
                      lineDash: line_dash
                  })
              });
          }
          else if (tipus_focus == "SOTERRADA") {

              line_dash = [2, 8];

              return new Style({
                  stroke: new Stroke({
                      width: stroke_width,
                      color: stroke_color,
                      lineDash: line_dash
                  })
              });

          }
          else if (tipus_focus == "SUPERFICIAL") {

              return new Style({
                  fill: new Fill({
                      color: fill_color
                  }),
                  stroke: new Stroke({
                      width: stroke_width,
                      color: stroke_color,
                      lineDash: line_dash
                  })
              });
          }
          else {
            return;
          }
        };
      },
      capaSwitch(type) {
        switch(type) {
          case 1:
            return this.urlCapa1();
          case 2:
            return this.urlCapa2();
          case 3:
            return this.urlCapa3();
        }
      },
      urlCapa1(extent) {
        return '/geoserver'+'/mosquits/wfs?' +
            'service=WFS&' +
            'version=1.0.0&request=GetFeature&typename=mosquits:superficial_de_focus&'+
            'outputFormat=application/json&bbox=' + extent.join(',') + ',EPSG:3857';
      },
      urlCapa2(extent) {
        return '/geoserver'+'/mosquits/wfs?' +
            'service=WFS&' +
            'version=1.0.0&request=GetFeature&typename=	mosquits:linial_visible_de_focus&'+
            'outputFormat=application/json&bbox=' + extent.join(',') + ',EPSG:3857';
      },
      urlCapa3(extent) {
        return '/geoserver'+'/mosquits/wfs?' +
            'service=WFS&' +
            'version=1.0.0&request=GetFeature&typename=mosquits:linial_soterrada_de_focus&'+
            'outputFormat=application/json&bbox=' + extent.join(',') + ',EPSG:3857';
      },
      urlCapa4(extent) {
        return '/geoserver'+'/mosquits/wfs?' +
            'service=WFS&' +
            'version=1.0.0&request=GetFeature&typename=mosquits:puntual_de_focus&'+
            'outputFormat=application/json&bbox=' + extent.join(',') + ',EPSG:3857';
      },
      loadingStrategyFactory () {
        return loadingBBox
      },
      loadFeatureInfo: async function() {
        if (this.selected.length) {
          let res = await fetch('/focus?id='+this.selected[0].properties.focus_id);
          let json = await res.json();
          if (res.ok != true) {
            this.errors= "Algo ha fallado al cargar /municipis";
          }
          else {
              this.dialog=true;
              this.focuss=json.data;
          }
        }
      },
      highlightFeature (event) {
        var feature = this.$refs.map.forEachFeatureAtPixel(event.pixel, (feature) => {
          return feature;
        });
        if (feature) {
          this.selected=feature
        }
      },
    },
  }
</script>



<style>
.a {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.map-wrapper {
  overflow-y: hidden;
}

.map {
  position:absolute;
  margin:0 auto;
  max-width: 2000px;
}


</style>
