<template>
  <div style="height: 98.5%; ">
  <v-tooltip
    v-if="!fab"
    left
    nudge-right="5"
    >
    <template v-slot:activator="{ on }">
      <v-btn
        fab
        dark
        small
        class="municipis"
        v-bind:color="municipi ? 'terciary' : 'error'"
        v-on:click="onMunicipiClick"
        v-on="on"
      >
        <v-icon> mdi-texture-box</v-icon>
      </v-btn>
    </template>
      <span>Termes municipals</span>
    </v-tooltip>




    <v-speed-dial
      v-model="fab"
      absolute
      bottom
      right
      transition="fade-transition"
      class="v-btn"

    >
      <template v-slot:activator >
        <v-btn v-model="fab" color="terciary" dark large fab :class="`elevation-0`">
          <v-avatar size="55">
            <img :src="mapa[num]" alt="John">
          </v-avatar>
        </v-btn>
      </template>

      <v-tooltip
        left
        nudge-right="5"
        >
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            large
            color="notselected"
            class="v-btn"
            v-on:click="selection((num+1)%4)"
            v-on="on"
          >
            <v-avatar size="58">
              <img :src="mapa[(num+1)%4]" alt="John">
            </v-avatar>
          </v-btn>
        </template>
        <span>{{mapa_name[(num+1)%4]}}</span>
      </v-tooltip>


      <v-tooltip
        left
        nudge-right="5"
        >
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            large
            color="notselected"
            class="v-btn"
            v-on:click="selection((num+2)%4)"
            v-on="on"
          >
            <v-avatar size="58">
              <img :src="mapa[(num+2)%4]" alt="John">
            </v-avatar>
          </v-btn>
        </template>
        <span>{{mapa_name[(num+2)%4]}}</span>
      </v-tooltip>


      <v-tooltip
        left
        nudge-right="5"
        >
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            large
            color="notselected"
            class="v-btn"
            v-on:click="selection((num+3)%4)"
            v-on="on"
          >
            <v-avatar size="58">
              <img :src="mapa[(num+3)%4]" alt="John">
            </v-avatar>
          </v-btn>
        </template>
        <span>{{mapa_name[(num+3)%4]}}</span>
      </v-tooltip>

    </v-speed-dial>




  </div>
</template>

<script>

import mapa1 from "../assets/mapa1.png"
import mapa2 from "../assets/mapa2.png"
import mapa3 from "../assets/mapa3.png"
import mapa4 from "../assets/mapa4.png"

export default {
  name: 'Menu',
  data: () => ({
    fab: false,
    prevnum:0,
    num: 0,
    mapa: [mapa1,mapa2,mapa3,mapa4],
    mapa_name: [`Clara`, `Carrers`,`Ortofoto`,`Topo 1:5000`],
    municipi: false,
  }),
  methods: {
    selection: function(a) {
      this.$emit('getmaptype',a%4)
      this.prevnum = this.num
      this.num = a;
    },

    onMunicipiClick: function() {
      this.municipi = !this.municipi
      this.$emit('getmunicipi',this.municipi)

    },
  }
}




</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.focus {
  position: fixed;
  bottom: 0em;
  right: 41.36em;
}
.focus-group {
  position: fixed;
  bottom: 0em;
  right: 6em;
}
.municipis {
  position: fixed;
  bottom: 6.6em;
  right: 0.5em;
}
.un {
}
.dos {
    /*  transform: translate3d(-3.5em,6.9em,0);*/
}
.tres {
    /*  transform: translate3d(-0.2em,8.2em,0);*/
}
.municipi {
    left:72.8em;
    top:60.85em;
}
.selected {
    background-color: #519CD2;
    color: #222;
}


.v-btn:before {
  display: none;
}

</style>
