<template>
  <div class="home">
    <HeaderMovil v-if="$vuetify.breakpoint.mdAndDown"/>
    <MenuMvl v-if="$vuetify.breakpoint.xsAndDown" v-on:getmaptype="setmaptype" v-on:getvisible="setvisible"/>
    <MapSideMenu  v-if="$vuetify.breakpoint.smAndUp" v-on:getmaptype="setmaptype" v-on:getmunicipi="setmunicipi" v-on:getvisible="setvisible" v-on:setUrba="setUrba"/>
    <Mapes :map="nmap" :municipi="municipi" :focus="focus" :urba="urba" v-bind:style="{width: '100%', height: '100%', position:'fixed'}" :centre.sync="centre"/>
  </div>
</template>

<script>
import Mapes from '../components/Map.vue'
import MapSideMenu from '../components/MapSideMenu.vue'
import MenuMvl from '../components/MenuMvl.vue'
import HeaderMovil from '../components/HeaderMovil.vue'


export default {
  name: 'Home',
  components: {
    Mapes,
    MapSideMenu,
    MenuMvl,
    HeaderMovil
  },

  data() {
    return {
      nmap: 0,
      centre: [226295.15, 5061664.94],
      focus: [true,true,true,true],
      municipi: false,
      productes: null,
      loading: true,
      response: 0,
      urba:[false,false]
    }
  },
  updated() {
  },
  methods: {
    setmaptype: function(a) {
      this.nmap = a;
    },
    setvisible: function(a) {
      if (a == -1 ) {
        this.focus = this.focus.map(bool => !bool)
      }
      else {
        this.focus.splice(a, 1, !this.focus[a])
      }
    },
    setmunicipi: function(a) {
      this.municipi = a;
    },
    setUrba(urba){
      this.urba = urba
    }
  },
  mounted: function() {
    let elHtml = document.getElementsByTagName('html')[0]
    elHtml.style.overflowY = 'hidden'
  },
  destroyed: function() {
    let elHtml = document.getElementsByTagName('html')[0]
    elHtml.style.overflowY = null
  }
}
</script>

<style>
.home .ol-viewport {
  border: 0;
  border-radius: 0;
}

.map-wrapper {
}

.map {
  position:relative;
  margin:0 auto;
  max-width: 2000px;
}


</style>
