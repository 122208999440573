<template>
    <v-navigation-drawer
      app
      clipped
      right
      disable-resize-watcher
      width="20%"
    >

    <v-alert  color="#247D72" text class="text-center mb-n1" >
      Control de Capes
    </v-alert>
    <v-divider ></v-divider>

    <v-list expand  class="sideVLGs">
      <!-- <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon> 
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item> -->

      <v-list-group  color="#247D72" :value="true" prepend-icon="mdi-layers" v-model="groupOpened1">
        <template v-slot:activator>
          <v-list-item-title> Bases</v-list-item-title>
        </template> 
        <div class="flex-layers-base list-layers">
          <div v-on:click="selection(0)">
            <div>
              <img class="responsive-img img-layers-base" :src="mapa[0]" :class="capa==0 ? 'img-layers-base-active' : ''">
            </div>
            <div class="flex-layers-base-title">
              Clara
            </div>
          </div>

          <div v-on:click="selection(1)">
            <div>
              <img class="responsive-img img-layers-base" :src="mapa[1]" :class="capa==1 ? 'img-layers-base-active' : ''">
            </div>
            <div class="flex-layers-base-title">
              Carrers
            </div>
          </div>

          <div v-on:click="selection(2)">
            <div>
              <img class="responsive-img img-layers-base" :src="mapa[2]" :class="capa==2 ? 'img-layers-base-active' : ''">
            </div>
            <div class="flex-layers-base-title">
              Ortofoto
            </div>
          </div>

          <div v-on:click="selection(3)">
            <div>
              <img class="responsive-img img-layers-base" :src="mapa[3]" :class="capa==3 ? 'img-layers-base-active' : ''">
            </div>
            <div class="flex-layers-base-title">
              Topo 1:5000
            </div>
          </div>
      </div>
      </v-list-group> 

   
      <v-list-group  color="#247D72" :value="true" prepend-icon="mdi-layers"  >
        <template v-slot:activator>
          <v-list-item-title> Focus</v-list-item-title>
        </template> 
        <v-list subheader flat>  
          <v-list-item v-on:click.capture.stop="onLayerClick(1)">
            <v-checkbox   
            color="header"
            class="mr-5"
            v-model="focus[0]"
          ></v-checkbox>
            <v-list-item-content  >
              <v-list-item-title>Lineal</v-list-item-title>
              <!-- <v-list-item-subtitle>Focus tipus lineal</v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-on:click.capture.stop="onLayerClick(2)">
            <v-checkbox
            color="header"
            class="mr-5  "
            v-model="focus[1]"
          ></v-checkbox>
            <v-list-item-content>
              <v-list-item-title>Superficial</v-list-item-title>
              <!-- <v-list-item-subtitle>Focus tipus Superficial</v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-on:click.capture.stop="onLayerClick(3)">
            <v-checkbox
            color="header"
            class="mr-5"
            v-model="focus[2]"
          ></v-checkbox>
            <v-list-item-content>
              <v-list-item-title>Lineal soterrat</v-list-item-title>
              <!-- <v-list-item-subtitle>Focus soterrat</v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-on:click.capture.stop="onLayerClick(4)">
            <v-checkbox
            color="header"
            class="mr-5"
            v-model="focus[3]"
          ></v-checkbox>
            <v-list-item-content>
              <v-list-item-title>Puntual</v-list-item-title>
              <!-- <v-list-item-subtitle>Focus puntual</v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-list-group>  

      <v-list-group color="#247D72" :value="true" prepend-icon="mdi-layers" >
        <template v-slot:activator>
          <v-list-item-title> Capes adicionals</v-list-item-title>
        </template> 
          <v-list
              subheader
              two-line
              flat
              multiple
          >
            <!-- <v-subheader>Municipis</v-subheader> -->
              <v-list-item
                v-on:click.capture.stop="onMunicipiClick"
              >
              <v-list-item-action>
                  <v-checkbox
                  color="header"
                  v-model="municipi"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Municipal</v-list-item-title>
                  <!-- <v-list-item-subtitle>Mostar els termes municipals</v-list-item-subtitle> -->
                </v-list-item-content>
            </v-list-item>
          </v-list>
      </v-list-group> 
      <v-list-group color="#247D72" :value="true" prepend-icon="mdi-layers" >
        <template v-slot:activator>
          <v-list-item-title> Capes Urbans</v-list-item-title>
        </template> 
          <v-list
              subheader
              one-line
              flat
              multiple
          > 
            <!-- <v-list-item 
            @click="urba.reixes = !urba.reixes"  
              >
                <v-list-item-action>
                  <v-checkbox
                  color="header"
                  :value="urba.reixes"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Reixes</v-list-item-title>
                  <v-list-item-subtitle>x els termes municipals</v-list-item-subtitle>  
                </v-list-item-content>
            </v-list-item> -->
            <v-list-item  
            @click="urba.embornals = !urba.embornals"  
            >
                <v-list-item-action>
                  <v-checkbox
                  color="header"
                  :value="urba.embornals"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content 
                >
                  <v-list-item-title>Embornals</v-list-item-title>
                  <!-- <v-list-item-subtitle>x els termes municipals</v-list-item-subtitle> -->
                </v-list-item-content>
            </v-list-item>
          </v-list>
      </v-list-group> 
        
    </v-list>
      
  
    </v-navigation-drawer>
</template>

<script>

import mapa1 from "../assets/mapa1.png"
import mapa2 from "../assets/mapa2.png"
import mapa3 from "../assets/mapa3.png"
import mapa4 from "../assets/mapa4.png"

export default {
  data () {
    return {
      groupOpened2:true,
      groupOpened1:true,
      mapa: [mapa1,mapa2,mapa3,mapa4],
      capa: 0,
      municipi: false,
      focus: [true,true,true,true],
      // urba:[false,false]
      urba:{
        reixes:false,
        embornals:false
      }
    }
  },
  methods: {
    selection: function(a) {
      this.capa = a;
      this.$emit('getmaptype',a)
    },
    onMunicipiClick: function() {
      this.municipi = !this.municipi
      this.$emit('getmunicipi',this.municipi)
      this.selected=!this.municipi;
    },
    onLayerClick: function(n) {
      if ( n == 0) {
        this.focus.splice(0, 1, !this.focus[0])
        this.focus.splice(1, 1, !this.focus[1])
        this.focus.splice(2, 1, !this.focus[2])
        this.focus.splice(3, 1, !this.focus[3])
      }
      else {
      this.focus.splice(n-1, 1, !this.focus[n-1])
      }
      this.$emit('getvisible',n-1)
    },
  },
  watch:{
    urba: {
        handler: function(v) {
          this.$emit('setUrba',[v.reixes,v.embornals])

        }, deep: true
    },
  }
}
</script>
<style scoped>

.rightmenu {
  margin-top: 4em;
}

.flex-layers-base{
  display:flex;
  justify-content:center;
  /*Cuando habian solo 4 justify-content: center;*/

  align-content: space-around;
  padding: 0.5rem 0.2rem 0.5rem 0.2rem;
  /*padding: 0.5rem;*/
  /*margin: 0;*/
  /*line-height:0;*/
   flex-wrap: wrap;

}
.flex-layers-base>div{
  margin-left: 0.14rem;
  margin-right: 0.14rem;
  /*Cuando habian 4*/
  /*margin-left:2px;
  margin-right:2px;*/
  position:relative;
}

.img-layers-base{
	border-radius: 16%;
	border: 2px solid #ddd;
	box-shadow: 1px 2px 2px grey;
	width: 75px;
	cursor:pointer;
}

.img-layers-base-active{
	border-radius: 16%;
	border: 2px solid #26a69a;
	box-shadow: 1px 2px 2px grey;
	width: 75px;
	cursor:pointer;

}

.flex-layers-base-title{
	text-align: center;
	position: absolute;
	bottom: 13px;
	width: 95%;
	left: 2px;
	background-color: rgba(255, 255, 255, 0.7);
	color: #26a69a;
	box-sizing: border-box;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	cursor:pointer;
  font-size: 0.8rem;
}
</style>
