<template>
    <v-app >
      <Header v-if="$vuetify.breakpoint.mdAndUp"/>
      <v-content>
        <router-view></router-view>

        <!-- Provides the application the proper gutter -->
        <v-container fluid>

          <!-- If using vue-router -->
        </v-container>
      </v-content>
    </v-app>
</template>

<script>
import Header from './components/Header.vue'

export default {
  name: 'app',
  components: {
    Header,
  },
  created () {
    document.title = "Servei de Control dels Mosquits";
  },
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>
<style>
.ol-viewport {
  border: 2px solid #65a69e;
  border-radius: 7px;
}
.ol-zoom button{
  background-color: #65a69e !important;
}
.v-list-group__header{
  background: #e2efee !important; 
}
.sideVLGs .v-list-item__icon {
  margin: 12px 0 !important;
  margin-right: 12px  !important;
}
.v-list-item{
  height: 48px !important;
}
</style>
